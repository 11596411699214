/* eslint-disable jsx-a11y/iframe-has-title */
// AboutPage.js
import React from 'react';
import Hinh from "../asset/portfolio-left-dec.png"
import Hinh2 from "../asset/tables-left-dec.png"
import MeHuongLan from "../asset/Me-Huong-Lan.jpg"
import BoVietAnh from "../asset/Bo-Viet-Anh.jpg"
import MeKhanhLinh from "../asset/Me-Khanh-Linh.jpg"
import Cardcommon from '../component/card';
function HinhAnh() {
    const cardData = [
        {
            avatarSrc: MeHuongLan,
            content: 'Mình đã rất phân vân có nên cho con học bóng đá thiếu nhi ở Hồ Chí Minh tại Đăng Phúc Sport hay không? Nhưng rồi mình cũng đã quyết định cho bé nhà mình đi học và ra sân xem con học, thi đấu thường xuyên. Cám ơn trung tâm!',
            authorName: 'Mẹ Minh Quân',
        },
        {
            avatarSrc: BoVietAnh,
            content: 'Một sân chơi bổ ích cho các con. Nơi mà các con được rèn luyện cả về kỹ năng, về tính kỷ luật, về sự chia sẻ cũng như tinh thần đồng đội. Chúc Đăng Phúc Sport nhiều thành công hơn nữa trong năm mới.',
            authorName: 'Bố Thanh Phát',
        },
        {
            avatarSrc: MeKhanhLinh,
            content: 'Mình đã đăng kí cho em trai lớn theo học bóng đá tại Trung tâm dạy bóng đá trẻ em. Rất hài lòng với môi trường dạy học ở đây. Sắp tới mình sẽ đăng kí tiếp cho cậu em út nữa.',
            authorName: 'Mẹ Quang Tùng',
        }
    ];
    return (
        <div id="services" className="our-services section">
            <div className="services-right-dec">
                <img src={Hinh} alt="" />
            </div>
            <div className="">
                <div className="services-left-dec">
                    <img src={Hinh2} alt="" />
                </div>
                <div className="flex justify-center">
                    <div className="section-heading">
                        <h2>
                            <em>CẢM NHẬN</em> TỪ PHỤ HUYNH VÀ HỌC VIÊN
                            {/* <span>CỦA CHÚNG TÔI</span> */}
                        </h2>
                        {/* <span>QUANH NĂM</span> */}
                    </div>
                </div>
            </div>
            <div className='px-5 sm:px-20 flex flex-col items-center md:flex-row md:justify-center md:gap-10'>
                {cardData.map((data, index) => (
                    <Cardcommon
                        key={index}
                        avatarSrc={data.avatarSrc}
                        content={data.content}
                        authorName={data.authorName}
                        
                    />
                ))}
            </div>

        </div>

    );
}

export default HinhAnh;
