import React, { useState } from "react";
import "./App.css";
import logo from "../src/asset/1.jpg";
import Banner from "../src/page/Banner"
import GioiThieu from "../src/page/GioiThieu"
import LichHoc from "../src/page/LichHoc";
import HinhAnh from "./page/HinhAnh";
import DanhGia from "./page/DanhGia";
import { Link } from "react-scroll";
import DangKi from "../src/page/DangKi"
import Footer from "./page/footer";
function App() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <nav className="fixed md:flex justify-between items-center w-full bg-white border-gray-200 shadow-md md:justify-around" style={{ zIndex: 99999 }}>
        <div className="flex justify-around items-center">
          <div className="max-w-screen-xl flex items-center justify-between md:mx-auto p-4">
            <Link activeClass="active" smooth spy to="gioithieu">
              <p className="flex items-center space-x-3 rtl:space-x-reverse cursor-pointer">
                <img src={logo} className="h-8" alt="Flowbite Logo" />
                <span className="self-center text-1xl font-semibold whitespace-nowrap">
		TRUNG TÂM ĐÀO TẠO BÓNG ĐÁ ĐĂNG PHÚC SPORT
                </span>
              </p>
            </Link>
          </div>

          <button
            onClick={toggleMenu}
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200  "
            aria-controls="navbar-default"
            aria-expanded={isOpen ? 'true' : 'false'}
          >
            <span className="sr-only">Open main menu</span>
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
            </svg>
          </button>

        </div>

        <div className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${isOpen ? '' : 'hidden'}`} id="navbar-default">
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white">
            <li>
              <Link activeClass="active" smooth spy to="gioithieu">
                <p
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 cursor-pointer"
                  aria-current="page"
                >
                  Giới thiệu
                </p>
              </Link>
            </li>
            <li>
              <Link activeClass="active" smooth spy to="khoahoc">
                <p
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 cursor-pointer"
                  aria-current="page"
                >
                  Khóa học
                </p>
              </Link>
            </li>
            <li>
              <Link activeClass="active" smooth spy to="lichhoc">
                <p
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 cursor-pointer"
                  aria-current="page"
                >
                  Lịch học
                </p>
              </Link>
            </li>
            <li>
              <Link activeClass="active" smooth spy to="hinhanh">
                <p
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  cursor-pointer"
                  aria-current="page"
                >
                  Hình ảnh
                </p>
              </Link>
            </li>
            <li>
              <Link activeClass="active" smooth spy to="danhgia">
                <p
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 cursor-pointer"
                  aria-current="page"
                >
                  Đánh giá
                </p>
              </Link>
            </li>
            <li>
              <Link activeClass="active" smooth spy to="dangki">
                <p
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0  cursor-pointer"
                  aria-current="page"
                >
                  Đăng kí
                </p>
              </Link>
            </li>
          </ul>
        </div>
      </nav>




      <section id='gioithieu'><Banner /></section>
      <section id='khoahoc'><GioiThieu /></section>
      <section id='lichhoc'><LichHoc /></section>
      <section id='hinhanh'><HinhAnh /></section>
      <section id='danhgia'><DanhGia /></section>
      <section id='dangki'><DangKi /></section>
      <Footer/>
    </>
  );
}

export default App;
