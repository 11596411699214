/* eslint-disable jsx-a11y/iframe-has-title */
// AboutPage.js
import React, { useEffect, useState } from 'react';
import Hinh from "../asset/videos-right-dec.png"
import Hinh2 from "../asset/videos-left-dec.png"
import axios from 'axios';
import ImagePreviewGroup from '../component/ImageDisplay';

interface Image {
  id: string;
  image_path: string;
}

interface ImageItem {
  src: string;
  alt: string;
  className: string;
}

function HinhAnh() {
  const [images, setImages] = useState<ImageItem[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://admin.dangphucsport.vn/api/images');
        console.log("images", response.data.data);
        
        // Mapping the fetched data to match the ImageItem type
        const fetchedImages = response.data.data.map((image: Image) => ({
          src: `https://admin.dangphucsport.vn/${image.image_path}?t=${new Date().getTime()}`, // Thêm timestamp để phá cache
          alt: `Image ${image.id}`,
          className: 'w-full h-full object-cover'
        }));
        
        setImages(fetchedImages);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to fetch images');
      }
    };

    fetchData();
  }, []);

  return (
    <div id="services" className="our-services section">
      <div className="services-right-dec">
        <img src={Hinh} alt="" />
      </div>
      <div className="">
        <div className="services-left-dec">
          <img src={Hinh2} alt="" />
        </div>
        <div className="flex justify-center">
          <div className="section-heading">
            <h2>
              <em>HÌNH ẢNH</em> LỚP HỌC BÓNG ĐÁ
            </h2>
          </div>
        </div>
      </div>
      <div className='flex justify-center'>
        <div className="w-4/5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-10">
          {error ? <p>{error}</p> : <ImagePreviewGroup images={images} />}
        </div>
      </div>
    </div>
  );
}

export default HinhAnh;
