import React from 'react';
import { Card, Avatar } from 'antd'; // Đảm bảo bạn đã import các component Card và Avatar từ thư viện Ant Design

interface CommonCardProps {
    avatarSrc: any;
    content: string;
    authorName: string;
}

const CommonCard: React.FC<CommonCardProps> = ({ avatarSrc, content, authorName }) => {
    return (
        <Card className='border-none shadow-md mb-10' style={{ width: 300 }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className='flex justify-between mb-5'>
                    <div className='flex' style={{ width: '20%' }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100%"
                            height="100%"
                            preserveAspectRatio="none"
                            viewBox="0 0 129.34 107.78"
                            fill="rgba(53, 93, 219, 1.0)"
                        >
                            <polygon points="0 107.78 0 50.18 24.87 0 55.85 0 35.35 48.44 36.66 50.18 56.73 50.18 56.73 107.78 0 107.78" />
                            <polygon points="72.61 107.78 72.61 50.18 97.48 0 128.47 0 107.96 48.44 109.27 50.18 129.34 50.18 129.34 107.78 72.61 107.78" />
                        </svg>
                    </div>
                    <div className='flex' style={{ width: '30%' }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="100%"
                            height="100%"
                            viewBox="0 0 24 24"
                            fill="rgba(255,174,15,1)"
                        >
                            {" "}
                            <path d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" />{" "}
                        </svg>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="100%"
                            height="100%"
                            viewBox="0 0 24 24"
                            fill="rgba(255,174,15,1)"
                        >
                            {" "}
                            <path d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" />{" "}
                        </svg>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="100%"
                            height="100%"
                            viewBox="0 0 24 24"
                            fill="rgba(255,174,15,1)"
                        >
                            {" "}
                            <path d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" />{" "}
                        </svg>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="100%"
                            height="100%"
                            viewBox="0 0 24 24"
                            fill="rgba(255,174,15,1)"
                        >
                            {" "}
                            <path d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" />{" "}
                        </svg>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="100%"
                            height="100%"
                            viewBox="0 0 24 24"
                            fill="rgba(255,174,15,1)"
                        >
                            {" "}
                            <path d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" />{" "}
                        </svg>
                    </div>
                </div>
                <p className='text-justify' style={{ marginBottom: 10 }}>
                    {content}
                </p>
                <Avatar src={avatarSrc} size={60} style={{ marginBottom: 10 }} />
                <p style={{ fontWeight: 'bold' }}>{authorName}</p>
            </div>
        </Card>
    );
};

export default CommonCard;
