// // src/components/ImageDisplay.tsx
// import React from 'react';
// import { Image } from 'antd';

// interface ImageDisplayProps {
//   src: string;
//   alt: string;
//   style?: React.CSSProperties; // Thay đổi từ className thành style
// }

// const ImageDisplay: React.FC<ImageDisplayProps> = ({ src, alt, style }) => {
//   return <Image src={src} alt={alt} style={style} />; // Sử dụng style ở đây
// };

// export default ImageDisplay;
import React from 'react';
import { Image } from 'antd';

interface ImageItem {
  src: string;
  alt: string;
  className?: string; // Thay đổi từ style thành className
}

interface Props {
  images: ImageItem[];
}

const ImagePreviewGroup: React.FC<Props> = ({ images }) => (
  <Image.PreviewGroup
    preview={{
      onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
    }}
  >
    {images.map((image, index) => (
      // Sử dụng className để kết hợp nhiều class
      <Image key={index} alt={image.alt} src={image.src} className={image.className} />
    ))}
  </Image.PreviewGroup>
);

export default ImagePreviewGroup;
