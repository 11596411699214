// AboutPage.js
import React from 'react';
import Hinh from "../asset/huan-luyen-vien.webp"

import icon1 from "../asset/service-icon-01.png"
import icon2 from "../asset/service-icon-02.png"
import icon3 from "../asset/service-icon-03.png"

function GioiThieu() {
  return (

    <div id="about" className="about-us section sm:px-28">
      <div className="sm:flex sm:flex-row">
        <div className="sm:w-1/2">
          <div><img src={Hinh} alt="" /></div>
        </div>
        {/* Nội dung của cột 1 */}
        <div className="sm:w-1/2 mr-10" style={{ zIndex: "99" }}>
          <div className="item ml-10">
            <span className="font-bold text-2xl text-justify">KHÓA HỌC BÓNG ĐÁ TẠI <span className="font-bold text-blue-500">ĐPS - Trung Tâm đào tạo bóng đá Đăng Phúc Sport</span></span>
            <p className='text-justify mt-5'>
              <span className="font-bold">Ở Trung Tâm Đào Tạo Bóng Đá Đăng Phúc Sport</span>, chúng tôi tổng hợp những tài liệu huấn luyện trong nước và quốc tế, xây dựng chương trình huấn luyện là những trải nghiệm và thử thách với các cầu thủ trẻ, giúp các em biết cách chơi với bóng đá, hướng đến những mục tiêu dài hạn, tạo điều kiện cho các em học hỏi, vui chơi và tăng cường năng lực bản thân
            </p>
            {/* <p className='text-justify'>
              <span className="font-bold">Môi trường bổ ích:</span> Lớp học bóng đá giúp các con được tập luyện trong môi trường thân thiện, năng động, vui vẻ và hòa đồng.
            </p> */}
            <p className='text-justify'>
              <span className="font-bold">Đội ngũ HLV xuất sắc:</span> Trung Tâm tự hào với 100% HLV là giảng viên các trường ĐH Sư Phạm Thể Dục Thể Thao, các cựu cầu thủ bóng đá chuyên nghiệp.
            </p>
            <p className='text-justify'>
              <span className="font-bold">Đến với chúng tôi:</span> các em nhỏ có cơ hội phát triển trong môi trường năng động, an toàn, chuyên nghiệp. Các em có thể tự tin hơn, thể hiện tinh thần đồng đội, kết nối bạn bè, tính kỷ luật, sự kiên trì làm việc nhóm, cư xử theo phong cách đẹp,... thông qua những bài tập về bóng đá. Đó là mục tiêu Đăng Phúc FC Sport như là một Trung Tâm bóng đá trẻ, thu nhận học viên nam nữ thuộc mọi loại tầng lớp.
            </p>
          </div>
          <div className="flex ml-10">
            <div className="lg:w-1/3 flex justify-center">
              <div className="fact-item">
                <div className="flex flex-col items-center">
                  <div className="icon">
                    <img src={icon1} alt="" />
                  </div>
                  <div className="count-digit">1000+</div>
                  <div className="count-title">Học viên</div>
                  {/* <p>Lorem ipsum dolor sitti amet, consectetur.</p> */}
                </div>
              </div>
            </div>
            <div className="lg:w-1/3 flex justify-center">
              <div className="fact-item">
                <div className="flex flex-col items-center">
                  <div className="icon">
                    <img src={icon2} alt="" />
                  </div>
                  <div className="count-digit">5</div>
                  <div className="count-title">Cơ sở trong TP.HCM</div>
                  {/* <p>Lorem ipsum dolor sitti amet, consectetur.</p> */}
                </div>
              </div>
            </div>
            <div className="lg:w-1/3 flex justify-center">
              <div className="fact-item">
                <div className="flex flex-col items-center">
                  <div className="icon">
                    <img src={icon3} alt="" />
                  </div>
                  <div className="count-digit">100+</div>
                  <div className="count-title">Giáo viên</div>
                  {/* <p>Lorem ipsum dolor sitti amet, consectetur.</p> */}
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

  );
}

export default GioiThieu;
