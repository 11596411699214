// HomePage.js
import React from "react";

function HomePage() {
  const redirectToMessenger = () => {
    window.open(
      "https://www.messenger.com/t/141274663114700/?messaging_source=source%3Apages%3Amessage_shortlink&source_id=1441792&recurring_notification=0",
      "_blank" // Điều này mở liên kết trong một tab hoặc cửa sổ mới
    );
  };
  const redirectToZalo = () => {
    window.open(
      "https://zalo.me/0339775337",
      "_blank" // Điều này mở liên kết trong một tab hoặc cửa sổ mới
    );
  };
  const handleCall = () => {
    // Thực hiện cuộc gọi điện thoại ở đây
    window.location.href = "tel:+0339775337"; // Thay số điện thoại bằng số cần gọi
  };
  return (
    <>
      <div className="main-banner" id="top">
        <div>
          <div className="sm:px-28 px-10 rounded-2xl overflow-hidden mb-10 " style={{ zIndex: '9', position: 'sticky' }}>
            <div className="item header-text mb-5">
              <span className="font-bold text-3xl text-orange-500 flex justify-center mb-5">CHÀO MỪNG ĐẾN VỚI ĐPS - TRUNG TÂM ĐÀO TẠO BÓNG ĐÁ ĐĂNG PHÚC SPORT</span>

              <p className='text-justify'>
                Nếu bạn chưa từng bao giờ nghĩ rằng đi đá bóng sẽ giúp <span className="font-bold"> "gặt về được cả ôm hạnh phúc"</span> thì hãy thử một lần đến với <span className="font-bold">ĐPS - Trung Tâm đào tạo bóng đá Đăng Phúc Sport</span>. Tôi tin rằng từng ánh mắt - tiếng cười - lời nói...không chỉ của các bé mà lan tỏa từ những người làm cha làm mẹ đứng quan sát con mình ở ngoài sân cỏ sẽ trả lời thay cho tất cả.
              </p>
             
            </div>
            <iframe
              width="100%"
              height={600}
              src="https://www.youtube.com/embed/sr11wrLv9VM?si=ojpCKC7VJ-nSJIIe"
              title="YouTube video player"
              frameBorder={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          </div>
        </div>        
      </div>
      <div
        className="grid grid-cols-1 grid-rows-2 custom fixed bg-white shadow-lg w-70 justify-center"
        style={{ zIndex: "9999" }}
      >
        <div
          className="cursor-pointer flex justify-center"
          onClick={redirectToMessenger}
        >
          <img
            className="w-1/2 mb-3 ml-3 mt-3 mr-3 rounded-full clear-both custom1"
            src="https://academy.vjss.com.vn/files/assets/messenger.png"
            alt="Messenger"
          />
          <div
            className="relative inline-flex"
            style={{ right: "1rem", top: "0.6rem" }}
          >
            <div className="w-3 h-3 bg-green-500 rounded-full"></div>
            <div className="w-3 h-3 bg-green-500 rounded-full absolute top-0 left-0 animate-ping"></div>
            <div className="w-3 h-3 bg-green-500 rounded-full absolute top-0 left-0 animate-pulse"></div>
          </div>
        </div>
        <div
          className="cursor-pointer flex justify-center"
          onClick={redirectToZalo}
        >
          <img
            className="w-1/2 mb-3 ml-3 mt-3 mr-3 rounded-full clear-both custom1"
            src="https://academy.vjss.com.vn/files/assets/zalo.png"
            alt="Zalo"
          />
          <div
            className="relative inline-flex"
            style={{ right: "1rem", top: "0.6rem" }}
          >
            <div className="w-3 h-3 bg-green-500 rounded-full"></div>
            <div className="w-3 h-3 bg-green-500 rounded-full absolute top-0 left-0 animate-ping"></div>
            <div className="w-3 h-3 bg-green-500 rounded-full absolute top-0 left-0 animate-pulse"></div>
          </div>
        </div>
        <div
          className="cursor-pointer flex justify-center"
          onClick={handleCall}
        >
          <img
            className="w-1/2 mb-3 ml-3 mt-3 mr-3 rounded-full clear-both custom1"
            src="https://academy.vjss.com.vn/files/assets/phone.png"
            alt="Messenger"
          />
          <div
            className="relative inline-flex"
            style={{ right: "1rem", top: "0.6rem" }}
          >
            <div className="w-3 h-3 bg-green-500 rounded-full"></div>
            <div className="w-3 h-3 bg-green-500 rounded-full absolute top-0 left-0 animate-ping"></div>
            <div className="w-3 h-3 bg-green-500 rounded-full absolute top-0 left-0 animate-pulse"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
